import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';

import { Action, Paragraph, Separator, Subtitle, TextBlock, TextString, Title } from 'ui/components';
import { breakpoint } from 'ui/settings';
import { Fold, Helmet, Layout } from 'ui/partials';
import { setSpace, setType } from 'ui/mixins';

const Projects = styled.ol``;

const Project = styled.li`
  width: 100%;
  &:not(:last-child) {
    ${setSpace('mbh')};
  }
  ${breakpoint.phone} {
    text-align: center;
  }
  ${breakpoint.desktopUp} {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    &:not(:last-child) {
      ${setSpace('pbh')};
    }
  }
`;

const ProjectCover = styled.figure`
  ${setSpace('mbm')};
  cursor: pointer;
  ${breakpoint.desktopUp} {
    ${setSpace('pll')};
    ${setSpace('mbn')};
    flex: 0 0 ${(100 / 3) * 2}%;
  }
`;

const ProjectDetails = styled.div`
  ${setType('x')};
  ${TextBlock} {
    ${Subtitle} {
      ${setSpace('mbl')};
    }
    ${Paragraph} {
      ${setSpace('mbm')};
    }
  }
  ${Subtitle} a {
    font-family: inherit;
  }
  ${breakpoint.desktopUp} {
    flex: 0 0 ${100 / 3}%;
  }
`;

const ProjectsListing = props => {
  const { edges: projects } = props.data.allMarkdownRemark;
  return (
    <Fragment>
      <Helmet {...props} title="Projects" />
      <Layout {...props}>
        <Fold>
          <TextBlock>
            <Title>
              <TextString looks="hcap">Portfolio</TextString>
              <TextString looks="h1">Selected Projects</TextString>
            </Title>
            <Paragraph looks="p2">
              From editorial websites to complex dashboards, data visualisations, large media productions, SaaS single
              page web applications and desktop apps—been there, done that.
            </Paragraph>
          </TextBlock>
        </Fold>
        <Separator ornamental size="h" />
        <Projects>
          {projects.map(({ node: project }) => (
            <Project key={project.id}>
              <ProjectCover onClick={() => navigate(`/made/${project.frontmatter.uid}`)}>
                <GatsbyImage
                  alt={project.frontmatter.title}
                  image={getImage(project.frontmatter.cover.childImageSharp)}
                />
              </ProjectCover>
              <ProjectDetails>
                <TextBlock>
                  <Subtitle looks="h3">
                    <Action to={`/made/${project.frontmatter.uid}`}>{project.frontmatter.title}</Action>
                  </Subtitle>
                  <Paragraph looks="p4">{project.frontmatter.summary}</Paragraph>
                  <Action button to={`/made/${project.frontmatter.uid}`}>
                    Learn more
                  </Action>
                </TextBlock>
              </ProjectDetails>
            </Project>
          ))}
        </Projects>
      </Layout>
    </Fragment>
  );
};

export default ProjectsListing;

// prettier-ignore
export const pageQuery = graphql`
  query AllProjectsQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/pages/made/.*.md/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            uid
            title
            summary
            cover {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
        }
      }
    }
  }
`;
